import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import Header from '../components/Header'
import SEO from '../components/Seo'
import Content from '../components/Content'
import Image from '../components/Image'

import './SinglePost.css'

const SinglePost = ({ data: { post } }) => {
  const { id, html, frontmatter } = post
  const { title, date, featured_image } = frontmatter
  return (
    <Layout>
      <SEO title={title} keywords={[]} />
      <Header />
      <div className="SinglePost">
        <article className="container skinnier">
          <h1 className="SinglePost--Title">{title}</h1>
          <Image
            className="SinglePost--FeaturedImage"
            src={featured_image}
            alt={title}
          />
          <Content className="SinglePost--Content" src={html} />
        </article>
      </div>
    </Layout>
  )
}

export default SinglePost

export const pageQuery = graphql`
  query BlogBySlug($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        date
        featured_image {
          ...TracedImage
        }
      }
    }
  }
`
